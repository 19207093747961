<script>
import LoadingSpinner from '../../../components/LoadingSpinner.vue'
import StyledButton from '../../../components/StyledButton.vue'

export default {
  name: 'CalendarSkeleton',
  components: {
    LoadingSpinner,
    StyledButton
  },
  props: {
    hideSearchBar: {
      type: Boolean,
      default: false
    },
    headingText: {
      type: String,
      default: ''
    },
    subHeadingText: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div class="w-full md:w-full lg:max-w-screen max-w-6xl md:px-0 mx-auto">
    <div class="flex flex-col gap-4 items-center">
      <!-- Search bar skeleton -->
      <div v-if="!hideSearchBar" class="text-center mt-20 flex flex-col items-center gap-6">
        <div v-if="headingText || subHeadingText" class="mt-2">
          <h1 class="bo:font-graphik-bold hj:font-druk-heavy-italic hj:text-f-white text-3xl text-center mb-2 leading-tight">
            {{ headingText }}
          </h1>
          <p class="text-f-bg bo:text-f-tertiary hj:text-f-white text-center">{{ subHeadingText }}</p>
        </div>
        <div class="py-2 px-4 inline-flex items-center cursor-pointer justify-center bg-f-tertiary/[.07] hj:bg-f-tertiary rounded-xl border-2 border-f-bg/[.07] hj:border-white/10 mx-auto gap-2">
          <div class="animate-pulse h-5 bg-gray-200/[0.5] rounded-md w-20"></div>
          <div class="text-gray-500">|</div>
          <div class="animate-pulse h-5 bg-gray-200/[0.5] rounded-md w-20"></div>
          <img 
            :src="asset('assets/magnifying-glass-dark.svg')" 
            alt="Search icon" 
            class="w-4 h-4 ml-3 opacity-50"
          />
        </div>
      </div>

      <!-- Heading skeleton -->
      <p class="md:hj:font-druk-heavy-italic hj:text-md md:text-lg md:hj:text-3xl text-center flex items-center justify-center mt-2 md:mt-10 mb:2 text-white">
        Loading Available Times <br />
        please wait...
      </p>

      <!-- Main content area skeleton -->
      <div class="w-full text-center py-12 px-5 mx-2 rounded-lg border drop-shadow-md border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary flex justify-center items-center">
        <LoadingSpinner size="medium" />
      </div>

      <!-- Navigation buttons skeleton -->
      <div class="flex justify-between w-full">
        <div class="px-4 md:px-6 py-1 md:py-2 font-bold rounded-xl w-32 
                    bg-transparent text-f-contrast hj:border-f-contrast bo:border-f-contrast border-2
                    opacity-60 pointer-events-none
                    hj:font-druk-bold hj:font-normal hj:text-2xl md:hj:text-2xl animate-pulse text-center">
          Loading...
        </div>
        <div class="px-4 md:px-6 py-1 md:py-2 font-bold rounded-xl w-32
                    bg-transparent text-f-contrast hj:border-f-contrast bo:border-f-contrast border-2
                    opacity-60 pointer-events-none
                    hj:font-druk-bold hj:font-normal hj:text-2xl md:hj:text-2xl animate-pulse text-center">
          Loading...
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
</style> 