<script setup>
import { computed, defineEmits } from "vue"

const emit = defineEmits(["click"])

const props = defineProps({
  mode: {
    type: String,
    default: "contained",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const computedClass = computed(() => {
  const baseClasses = [
    "px-4 md:px-6  py-1 md:py-2 font-bold rounded-xl cursor-pointer hj:font-druk-bold hj:font-normal hj:text-2xl md:hj:text-2xl",
  ]

  const modeClasses = {
    contained: [
      "bo:bg-f-contrast hj:bg-f-contrast hj:text-f-white bo:text-f-tertiary",
      "hover:bo:text-f-white hover:bo:bg-f-tertiary hover:hj:bg-f-primary hover:text-f-white",
    ],
    outlined: [
      "bg-transparent text-f-contrast hj:border-f-contrast bo:border-f-contrast border-2",
      "hover:hj:bg-f-contrast hover:hj:bg-f-primary hover:text-f-white",
    ],
  }

  if (props.disabled) {
    baseClasses.push("pointer-events-none opacity-60")
  } else {
    baseClasses.push(...(modeClasses[props.mode] || []))
  }

  return baseClasses
})

const handleClick = () => {
  if (!props.disabled) {
    emit("click")
  }
}
</script>

<template>
  <button :class="computedClass" :disabled="disabled" @click="handleClick">
    <slot></slot>
  </button>
</template>
